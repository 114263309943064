// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-app-jsx": () => import("./../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-course-jsx": () => import("./../src/pages/course.jsx" /* webpackChunkName: "component---src-pages-course-jsx" */),
  "component---src-pages-courses-jsx": () => import("./../src/pages/courses.jsx" /* webpackChunkName: "component---src-pages-courses-jsx" */),
  "component---src-pages-debates-for-schools-jsx": () => import("./../src/pages/debates-for-schools.jsx" /* webpackChunkName: "component---src-pages-debates-for-schools-jsx" */),
  "component---src-pages-debates-for-teachers-jsx": () => import("./../src/pages/debates-for-teachers.jsx" /* webpackChunkName: "component---src-pages-debates-for-teachers-jsx" */),
  "component---src-pages-debates-in-english-jsx": () => import("./../src/pages/debates-in-english.jsx" /* webpackChunkName: "component---src-pages-debates-in-english-jsx" */),
  "component---src-pages-debates-jsx": () => import("./../src/pages/debates.jsx" /* webpackChunkName: "component---src-pages-debates-jsx" */),
  "component---src-pages-debates-of-the-week-jsx": () => import("./../src/pages/debates-of-the-week.jsx" /* webpackChunkName: "component---src-pages-debates-of-the-week-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-register-jsx": () => import("./../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-single-jsx": () => import("./../src/pages/single.jsx" /* webpackChunkName: "component---src-pages-single-jsx" */),
  "component---src-pages-talk-to-us-jsx": () => import("./../src/pages/talk-to-us.jsx" /* webpackChunkName: "component---src-pages-talk-to-us-jsx" */),
  "component---src-pages-talks-and-courses-jsx": () => import("./../src/pages/talks-and-courses.jsx" /* webpackChunkName: "component---src-pages-talks-and-courses-jsx" */),
  "component---src-pages-top-50-jsx": () => import("./../src/pages/top-50.jsx" /* webpackChunkName: "component---src-pages-top-50-jsx" */),
  "component---src-templates-single-jsx": () => import("./../src/templates/single.jsx" /* webpackChunkName: "component---src-templates-single-jsx" */)
}

